<template>
    <div>
        <div class="media">
            <div class="media-middle">
                Account page
            </div>



        </div>


    </div>
</template>

<script>
    import {reactive     } from 'vue';
    //import {onBeforeMount} from 'vue';
    //import {useStore     } from 'vuex';
    import axios           from 'axios';
    import {useAuth      } from '@websanova/vue-auth/src/v3.js';
    import useAuthComp     from '../../../src/composables/useAuthComp.js';

    export default {
        setup() {
            const auth     = useAuth();
            //const store    = useStore();
            const authComp = useAuthComp();

            const state = reactive({
                form: {
                    status: null
                }
            });



            function loading() {
                state.form.status = 'loading';
            }

            function complete() {
                state.form.status = 'complete';
            }

            function error() {
                state.form.status = 'error';
            }

            function fetchManual() {
                loading();

                axios({
                    url: 'auth/user'
                })
                .then((res) => {
                    var user = res.data.data;

                    user.first_name = 'Yay!';

                    auth.user(user);
                    //$auth.user(user);

                    complete();
                }, error);
            }

            function fetchDefault() {
                loading();

                auth
                .fetch()
                .then(complete, error);
            }

            function fetchComp() {
                loading();

                authComp.fetch().then(complete, error);
            }

            function refreshDefault() {
                loading();

                auth
                .refresh()
                .then(complete, error);
            }

            function refreshComp() {
                loading();

                authComp.refresh().then(complete, error);
            }

            return {
                state,
                fetchComp,
                fetchManual,
                fetchDefault,
                refreshComp,
                refreshDefault,
            }
        }

    }
</script>
